import GroupsController from './GroupsController';
import { Controller } from '../../../common/controllers/groups-list/Controller';
import type { GroupsWidgetProps } from '../../../common/types/groups-list/types';
import type { ControllerParams, ExperimentsBag } from '@wix/yoshi-flow-editor';

export class GroupsWidgetController extends Controller<GroupsWidgetProps> {
  constructor(
    controllerContext: ControllerParams,
    experiments: ExperimentsBag = {},
  ) {
    super(controllerContext);
    this.controllers.push(new GroupsController(controllerContext));
  }
}
