import { ApiErrorCode } from '../../../components/Group/legacy-controllers/errorHandler/IErrorEvent';

export interface IApplicationError {
  message: string;
  // TODO: find the right way to type this. Before, that was a WixApi.Details (Grps error) type here
  details: any;
}
export class ApplicationError extends Error implements IApplicationError {
  readonly details: any;

  constructor(err: IApplicationError) {
    super(err.message);
    this.details = err.details || {};
  }

  getCode() {
    return this.details?.applicationError?.code as ApiErrorCode;
  }

  getErrorData<T>() {
    return this.details.applicationError?.data as T;
  }
}
