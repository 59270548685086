import { SiteAppSectionInstalledInput } from '@wix/native-components-infra/dist/src/types/types';

export interface IPaidPlan {
  planId: string;
  name: string;
  startsAt: string;
}

export interface IPaidPlans {
  requiredPlans?: IPaidPlan[];
  futurePlans?: IPaidPlan[];
}

export const PAID_PLANS: SiteAppSectionInstalledInput = {
  appDefinitionId: '1522827f-c56c-a5c9-2ac9-00f9e6ae12d3',
  sectionId: 'membership_plan_picker_tpa',
};

export const INVITE_PAID_PLANS = 'invite_paid_plans';
