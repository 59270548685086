import { IWebStorage } from '@wix/native-components-infra/dist/src/types/types';
import { ControllerParams } from '@wix/yoshi-flow-editor';
import type { GroupResponse as ApiTypesV1GroupResponse } from '@wix/ambassador-social-groups-v1-group/types';
import { BasicStorage } from '@wix/social-groups-api';
import { PlatformStorage } from './PlatformStorage';

export class GroupsStorage extends PlatformStorage {
  static fromControllerConfig(
    controllerConfig: ControllerParams['controllerConfig'],
  ): GroupsStorage {
    let storage: IWebStorage;
    try {
      storage = controllerConfig.platformAPIs.storage.session;
    } catch (e) {
      console.error('Error in [GroupsStorage.fromControllerConfig]: ', e);
      storage = new BasicStorage();
    }
    return new GroupsStorage(storage);
  }

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(storage: IWebStorage) {
    super(storage);
  }

  getGroup(groupId: string): ApiTypesV1GroupResponse | null {
    try {
      const _group = this.storage.getItem(groupId);
      if (!_group) {
        console.log(`${groupId} is not in the storage`);
        return null;
      }
      const group = JSON.parse(_group) as ApiTypesV1GroupResponse;
      const isValidGroup = group.groupId === groupId || group.slug === groupId;
      console.log(`${groupId} isValidGroup ${isValidGroup}`);
      return (isValidGroup && group) || null;
    } catch (e) {
      console.error('Error in [GroupsStorage.getGroup]: ', e);
    }
    return null;
  }

  removeGroup(groupId: string): void {
    const group = this.getGroup(groupId);
    if (group) {
      const { groupId: id, slug } = group;
      this.storage.removeItem(id!);
      this.storage.removeItem(slug!);
    }
  }

  setGroup(group: ApiTypesV1GroupResponse): void {
    try {
      const { groupId, slug } = group;
      const cachedGroup = JSON.stringify(group);
      this.storage.setItem(groupId!, cachedGroup);
      this.storage.setItem(slug!, cachedGroup);
    } catch (e) {
      console.error('Error in [GroupsStorage.setGroup]: ', e);
    }
  }
}
