import {
  MembershipQuestion,
  MembershipQuestionAnswer,
} from '@wix/ambassador-social-groups-v2-membership-question/types';

import { Event } from '@wix/ambassador-events-v1-event/types'; // TODO: have ambassador2 types
import { LeaveRequest } from '@wix/ambassador-social-groups-v2-group-member/types';
import { Group } from '@wix/ambassador-social-groups-v2-group/types';
import { CancelJoinGroupRequestRequest } from '@wix/ambassador-social-groups-v2-join-group-request/types';
import { ApiErrorCode } from '../../../components/Group/legacy-controllers/errorHandler/IErrorEvent';
import { Member } from '@wix/ambassador-members-v1-member/types';
import { IPaidPlans } from '../../api/errors/IPaidPlans';

export interface IUserActions {
  promptLogin(options?: { modal: boolean }): void;

  makeProfilePublic(): void;
  confirmJoinWithPrivateProfile(groupId: string): void;
  refuseToJoin(groupId: string): void;

  changeGroupMembership(
    group: Group,
    membershipQuestionAnswers?: MembershipQuestionAnswer[],
    fromOrigin?: string,
  ): void;

  ignoreException(): void;

  leaveGroup(payload: LeaveRequest): void;

  cancelJoinGroupRequest(payload: CancelJoinGroupRequestRequest): void;

  pickPricingPlan(res: UserRequestResponse): void;

  openUserProfile(siteMemberId: string): void;
}

export enum UserStatus {
  UNKNOWN,
  LOGGED_IN,
  PRIVATE_PROFILE,
  UPDATING,
  OK,
}

export enum UserException {
  PRIVATE_PROFILE_RESTRICTION = 'PRIVATE_PROFILE_RESTRICTION',
  MEMBER_SERVICE_ERROR = 'MEMBER_SERVICE_ERROR',
  JOIN_TO_GROUP_REQUIRED = 'JOIN_TO_GROUP_REQUIRED', // non group member want to create a comment
  MEMBERS_QUESTIONS_MISSING = 'MEMBERS_QUESTIONS_MISSING',
}

export interface UserPermissions {
  canCreateGroup?: boolean;
  canResolvePendingGroups?: boolean;
}

export interface UserRequestResponse extends IPaidPlans {
  group: Group;
  events?: Event[];
  questions?: MembershipQuestion[];
  exception?: UserException | ApiErrorCode | null;
  membershipResponse?: any;
}
export interface IUserContext {
  userRequestResponse: UserRequestResponse | null;
  userStatus: UserStatus;
  userPermissions: UserPermissions;
  userActions: IUserActions;
  myMember: Member | null;
  isSiteAdmin: boolean;
  changeMembershipOrigin?: string;
}
