import { IGroupsSettingsParams, groupsSettingsParams } from 'settings/groups';
import { IFeedSettingsParams, feedSettingsParams } from 'settings/feed';

export interface IGroupsPageSettings
  extends IGroupsSettingsParams,
    IFeedSettingsParams {}

const settingsParams: IGroupsPageSettings = {
  ...groupsSettingsParams,
  ...feedSettingsParams,
};

export default settingsParams;
