// https://devforum.wix.com/en/article/wix-style-parameters
export enum FontTheme {
  HEADING_1 = 'Title',
  HEADING_2 = 'Page-title',
  HEADING_3 = 'Heading-XL',
  HEADING_4 = 'Heading-L',
  HEADING_5 = 'Heading-M',
  HEADING_6 = 'Heading-S',
  PARAGRAPH_1 = 'Body-L',
  PARAGRAPH_2 = 'Body-M',
  PARAGRAPH_3 = 'Body-S',
}

export const POST_PLACEHOLDER_COLOR = 'color-4';
export const POST_TEXT_COLOR = 'color-5';
export const DEFAULT_GRID_CARD_SPACING = 12;
export const MAX_BUTTON_BORDER_RADIUS = 100;
export const MAX_LAYOUT_SPACING = 100;
export const POST_ICONS_COLOR = 'color-5';

export enum Alignment {
  left = 'left',
  right = 'right',
  center = 'center',
}

export enum ButtonType {
  rectangle = 'rectangle',
  rounded = 'rounded',
}

export enum LayoutType {
  grid = 'grid',
  list = 'list',
  listWidget = 'listWidget',
  sideBySide = 'sideBySide',
  sideBar = 'sideBar',
}

export enum ImageRatio {
  rectangle = 'rectangle',
  square = 'square',
}

export enum TextAlignment {
  Left = 'Left',
  Center = 'Center',
  Right = 'Right',
}

export enum ImageCrop {
  CROP = 'CROP',
  FIT = 'FIT',
}

export enum ImageLayout {
  RTL = 'RTL',
  LTR = 'LTR',
  MIXED = 'MIXED',
}

export enum CoverImageLayout {
  thumbnail = 'thumbnail',
  large = 'large',
  blank = 'blank',
}

export enum MediaLayout {
  grid = 'grid',
  masonry = 'masonry',
  collage = 'collage',
}

export type MediaImagesPerRow = 3 | 4 | 5 | 6;
