import { IControllerContext } from 'common/controllers/IControllerContext';
import { CustomUrlSegments, getGroupUrl } from 'common/controllers';

export class SiteNavigation {
  constructor(private readonly context: IControllerContext) {}

  getGroupUrl(segments: CustomUrlSegments) {
    const location = this.context.getLocation();
    const siteApi = this.context.getSiteApis();
    return getGroupUrl(location, siteApi, segments);
  }

  async goToGroup(segments: CustomUrlSegments) {
    const { isViewer } = this.context.getEnvironment();
    if (isViewer) {
      const groupUrl = await this.getGroupUrl(segments);
      const location = this.context.getLocation();
      location.to?.(`${groupUrl}#preventScrollToTop`);
    } else {
      return this.navigateInEditor(segments.groupId);
    }
  }

  async navigateInEditor(groupId: string) {
    const location = this.context.getLocation();
    const siteApi = this.context.getSiteApis();
    if (
      typeof window === 'undefined' &&
      location.navigateToSection === undefined
    ) {
      const section = {
        sectionId: 'group',
        appDefinitionId: this.context.getAppDefinitionId(),
      };
      const { relativeUrl } = await siteApi.getSectionUrl(section);
      const options = { queryParams: { groupId } };

      return location.to!(this.buildEditorUrl(relativeUrl!, options));
    }

    const { compId } = this.context.getControllerConfig();
    const section = {
      sectionId: 'group',
      noTransition: true,
      state: '',
      queryParams: { groupId },
      compId,
    };
    if (typeof window === 'undefined') {
      return location.navigateToSection(section);
    }
    return window.Wix.Utils.navigateToSection(section, console.error);
  }

  private buildEditorUrl(relativeUrl: string, options: any) {
    let url = `${relativeUrl}`;

    if (options.queryParams !== undefined) {
      url += `?appSectionParams=${JSON.stringify(options.queryParams)}`;
    }

    return url;
  }
}
