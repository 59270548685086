export function transformSettingsKey(
  originalKey: string,
  { isMobile }: { isMobile: boolean },
): string {
  const keyWithUpperFirst = originalKey[0].toUpperCase() + originalKey.slice(1);
  return isMobile ? `mobile${keyWithUpperFirst}` : originalKey;
}

export function doNotTransformSettingsKey(originalKey: string): string {
  return originalKey;
}
