/**
 * List of FedOps interaction. Please use this file if you want to add more.
 *
 * Variable name (rule of thumb): it's better to give a meaningful name with a context of which widget/page is using this interaction.
 * If interaction happened in several widgets, those interactions should be duplicated for each widget in fedops.json file.
 *
 * Interaction values: have to be tha same as defined in fedops.json
 */

export const FEDOPS_GROUP_PAGE__JOIN_TO_GROUP = 'join-to-group' as const;
