import { ControllerParams } from '@wix/yoshi-flow-editor';
import {
  ILocation,
  ISiteApis,
  IUser,
  IWixWindowViewMode,
} from '@wix/native-components-infra/dist/src/types/types';
import getBaseUrl from '../utils/baseUrl';
import { IApiContext, IWebStorage } from '@wix/social-groups-api';
import { IControllerContext } from 'common/controllers/IControllerContext';
import { ControllerConfig } from 'common/types/WixCodeApi';

export class BaseControllerContext implements IApiContext, IControllerContext {
  protected readonly controllerConfig!: ControllerParams['controllerConfig'];
  protected readonly flowAPI: ControllerParams['flowAPI'];

  constructor(protected readonly controllerContext: ControllerParams) {
    this.controllerConfig = controllerContext.controllerConfig;
    this.flowAPI = controllerContext.flowAPI;
  }

  getWebStorage(): IWebStorage {
    return this.getControllerConfig().platformAPIs.storage.memory;
  }

  getControllerConfig(): ControllerConfig {
    return this.controllerContext.controllerConfig;
  }

  getEnvironment() {
    return this.controllerContext.flowAPI.environment;
  }

  getCurrentUser(): IUser {
    return this.controllerConfig.wixCodeApi.user.currentUser;
  }

  isUserLoggedIn(): boolean {
    return this.getCurrentUser().loggedIn;
  }

  protected isUserAdmin(): boolean {
    return this.getCurrentUser().role === 'Admin';
  }

  getSiteToken() {
    return this.controllerConfig.wixCodeApi.site.getAppToken?.(
      this.controllerConfig.appParams.appDefinitionId,
    )!;
  }

  getAppDefinitionId() {
    return this.controllerConfig.appParams.appDefinitionId;
  }

  getLocale() {
    return this.controllerConfig.wixCodeApi.window.locale || 'en';
  }

  getSiteLanguage() {
    return this.controllerConfig.wixCodeApi.site.language || 'en';
  }

  protected getApiBaseUrl() {
    return getBaseUrl(this.controllerConfig.wixCodeApi);
  }

  getLocation(): ILocation {
    return this.controllerConfig.wixCodeApi.location;
  }

  getSiteApis(): ISiteApis {
    return this.controllerConfig.wixCodeApi.site;
  }

  protected getViewMode(): IWixWindowViewMode {
    return this.controllerConfig.wixCodeApi.window.viewMode;
  }

  protected onUserLogin(callback: any) {
    this.controllerConfig.wixCodeApi.user.onLogin((user: any) => {
      callback(user);
    });
  }

  protected promptLogin(options: Partial<any> = {}) {
    return this.controllerConfig.wixCodeApi.user.promptLogin({
      lang: this.controllerConfig.wixCodeApi.site.language,
      ...options,
    });
  }

  protected isEditorMode() {
    const { viewMode } = this.controllerConfig.wixCodeApi.window;
    // TODO: now Preview is OOI. WILL BREAK
    return ['Editor', 'Preview'].includes(viewMode);
  }

  isSSR() {
    return this.flowAPI.environment.isSSR;
  }

  getMetaSiteId(): string {
    return this.controllerConfig.platformAPIs.bi?.metaSiteId || '';
  }

  getHTTPClient() {
    return this.flowAPI.httpClient;
  }

  getTranslations() {
    return this.flowAPI.translations;
  }
}
